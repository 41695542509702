import React from 'react'
import { Anchor, Divider } from 'antd'
import Container from '../components/Landing/Container'
import { CalendarOutlined, FundOutlined } from '@ant-design/icons'
import { isMobile } from 'react-device-detect'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import SignupBelt from '../components/Landing/SignupBelt'
import { Typography } from '@mui/material'

const { Link } = Anchor

export default function GeoSnapshotGuidePage({ setCookieValue, data }) {
    const affixContainerStyle: React.CSSProperties = isMobile
        ? {}
        : { display: 'grid', gridTemplateColumns: '1fr 6fr 1fr', marginLeft: 10, marginRight: 10 }

    return (
        <Container setCookieValue={setCookieValue} pathName="geosnapshot-guide">
            <SEO title="GeoSnapshot Guide" />
            <span style={affixContainerStyle}>
                {!isMobile && (
                    <Anchor offsetTop={100} targetOffset={156}>
                        <Link href="#guide-title" title="GeoSnapshot">
                            <Link href="#session" title="Session" />
                        </Link>
                        <Link href="#how-to" title="How to">
                            <Link href="#img" title="Billede" />
                            <Link href="#sms" title="SMS" />
                            <Link href="#save" title="Gem & afslut" />
                            <Link href="#nopdf" title="Uden PDF" />
                        </Link>
                        <Link href="#doc" title="Dokumentation">
                            <Link href="#gsid" title="Snapshot ID" />
                            <Link href="#loc" title="Lokation" />
                            <Link href="#device" title="Enhed" />
                            <Link href="#time" title="Tidsstempel" />
                            <Link href="#selfie" title="Selfie" />
                            <Link href="#smsveri" title="SMS" />
                            <Link href="#link" title="Verifikationslink" />
                        </Link>
                    </Anchor>
                )}
                <div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // flexWrap: 'wrap',
                            marginBottom: 10,
                        }}
                        id="guide-title"
                    >
                        <h1 style={{ fontSize: 40 }} className="landing-guide-title-h">
                            GeoSnapshot
                        </h1>
                        <p className="landing-guide-header-p">
                            GeoSnapshot-funktionen har to hovedformål.
                            <br />
                            <br />
                            1. At gøre det let i realtid atregistrere dit opholdssted.
                            <br />
                            <br />
                            Hermed ajourføres ”den specificerede opgørelse” over opholdssteder for
                            de kalenderdage, hvor du har opholdt dig i udlandet og i Danmark
                            <br />
                            (”den specificerede opgørelse” = Den opgørelse som Skattestyrelsen
                            anmoder om ved{' '}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: 'underline' }}
                                href="https://ll33a.dk/blog/materiale-indkaldelse"
                            >
                                materialeindkaldelser (se punkt 11))
                            </a>
                            <br />
                            <br />
                            <br />
                            2. At ved hjælp af din enhed at optage og arkivere oplysninger der
                            knytter dig og din enhed sammen med dette opholdssted, til senere
                            dokumentationsbrug overfor Skattestyrelsen.
                            <br />
                            <br />
                            <Typography id="session" component="span" fontWeight="bold">
                                GeoSnapshot-session
                            </Typography>
                            <br />
                            Med et GeoSnapshot gives der mulighed for at få autogenereret et
                            dokument, der indeholder oplysninger om dit GeoSnapshot. Oplysninger som
                            tilsammen dokumenterer dit opholdssted.
                            <br />
                            Processen involverer at indsamle data i individuelle parametre inden for
                            et kort tidsrum (typisk ca. 20 sek.) og præsentere disse data i en
                            sammenhængende kontekst.
                            <br />
                            Vi kalder denne proces for en GeoSnapshot-session.
                            <br />
                            <br />
                            Hver enkelte parameter, der bliver indsamlet, indgår i den samlede
                            kontekst, hvor du personligt kan blive forbundet med et specifikt
                            opholdssted på et bestemt tidspunkt.
                        </p>
                        <Img
                            title="Guide Image"
                            alt="step 1"
                            style={{ marginBottom: 100 }}
                            className="image-article"
                            fluid={data.s1.childImageSharp.fluid}
                        />
                        <Typography className="landing-guide-header-p">
                            Disse parametre registreres i realtid og låses fast i den unikke og
                            tidsafgrænsede GeoSnapshot-session, hvilket er med til at sikrer
                            autenciteten af de indsamlede data.
                            <br />
                            <br />
                            GeoSnapshot-sessionen danner fundamentet for det automatisk genererede
                            dokument. Alle detaljer fra sessionen præsenteres og arkiveres i et
                            PDF-dokument, som du nemt kan finde i din oversigt over dokumentation,
                            på kalendersiden under datoen for det pågældende GeoSnapshot. Hvis det
                            er markeret som "Included" (som er standardindstillingen), vil
                            dokumentet blive inkluderet i compliance-rapporten.
                            <br />
                        </Typography>
                        <Img
                            title="Guide Image"
                            alt="step 2"
                            style={{
                                marginBottom: 50,
                                marginTop: 50,
                            }}
                            className="image-article"
                            fluid={data.s2.childImageSharp.fluid}
                        />
                        <Typography className="landing-guide-header-p">
                            <Typography id="how-to" component="span" fontWeight="bold">
                                How to do
                            </Typography>
                            <br />
                            Ved at klikke på GeoSnapshot ikonet (øverst til højre på kalendersiden)
                            åbnes en tidsafgrænset GeoSnapshot-session, og du får nu en række valg.
                            <br />
                            <br />
                            Du kan vælge:
                            <br />
                            <br />
                            <Typography id="img" component="span" fontWeight="bold">
                                Tilføj billede
                            </Typography>{' '}
                            = Kamerafunktionen åbnes til selfie optagelse
                            <br />
                            Det er kun muligt at inkludere et foto optaget med enhedens kamera i
                            realtid i den tidsafgrænsede GeoSnapshot-session.Foto arkiveres direkte
                            på ll33a.dk's server.
                            <br />
                            <br />
                            <Typography id="sms" component="span" fontWeight="bold">
                                Tilføj SMS verifikation
                            </Typography>{' '}
                            = SMS-funktionen åbnes med præ-udfyldt;
                            <br />
                            - Telefonnummer til ll33a.dk's SMS-gateway
                            <br />
                            - Tekstfelt bestående af unik session-kode
                            <br />
                            Du skal nu aktivt afsende SMS'en ved at klikke “Send” i din SMS-app.
                            <br />
                            (For at SMS-verifikationen kan indgå i din dokumentation, er det
                            vigtigt, at du ikke ændrer i tekstfeltet)
                            <br />
                            Herefter skal du tilbage i ll33a.dk app'en og afslutte sessionen.
                            <br />
                            (Benyt evt. genvejen oppe i venstre hjørne i din Besked app.)
                            <br />
                            <br />
                            <Typography id="save" component="span" fontWeight="bold">
                                Gem & afslut
                            </Typography>
                            <br />
                            “Den specificerede opgørelse” over opholdssteder ajourføres, og
                            <br />
                            det auto-genererede dokument gemmes.
                            <br />
                            Har du anvendt ”Tilføj billede”, inkluderes din selfie i dokumentet.
                            <br />
                            Har du anvendt ”Tilføj SMS verifikation”, inkluderes verifikationen i
                            dokumentet.
                            <br />
                            <br />
                            <Typography id="nopdf" component="span" fontWeight="bold">
                                Afslut uden PDF
                            </Typography>{' '}
                            = GeoSnapshot som FØR
                            <br />
                            Den specificerede opgørelse over opholdssteder ajourføres uden
                            dokumentation.
                            <br />
                            <br />
                            En GeoSnapshot-session, inkl. selfie og SMS-verifikation, kan typisk;
                            åbnes, udføres og afsluttes, inden for ca. 20 sekunder.
                        </Typography>
                        <br />
                        <br />
                        <Typography id="doc" variant="h5" className="landing-guide-header-p">
                            GeoSnapshot-dokumentation - hvad omfatter det
                        </Typography>
                        <Img
                            title="Guide Image"
                            alt="step 2"
                            style={{ marginBottom: 50, marginTop: 50 }}
                            className="image-article"
                            fluid={data.s3.childImageSharp.fluid}
                        />
                        <Typography className="landing-guide-header-p">
                            GeoSnapshot dokumenterer dit opholdssted ved hjælp af din enheds
                            aktuelle lokation samt en række parametre, der opsnappes og fastlåses i
                            tidsrummet under en GeoSnapshot-session; herunder:
                            <br />
                            <br />
                            <Typography id="gsid" component="span" fontWeight="bold">
                                GeoSnapshot-ID
                            </Typography>
                            <br />
                            Ethvert GeoSnapshot tildeles et unikt ID.
                            <br />
                            Anvendes bla. til efterfølgende verificering af dokumentet.
                            <br />
                            <br />
                            <Typography id="loc" component="span" fontWeight="bold">
                                Lokation
                            </Typography>
                            <br />
                            Din enheds position bestemmes ud fra en kombination af mastedata, GPS og
                            WIFI. Positionen angives i længde- og breddegrad med en angivet
                            nøjagtighed indenfor 50m.
                            <br />
                            Denne position angives også med stednavn eller adresse for benævnelse af
                            dit aktuelle opholdssted.
                            <br />
                            <br />
                            <Typography id="device" component="span" fontWeight="bold">
                                Enhed (device)
                            </Typography>
                            <br />
                            Om din anvendte enhed registreres, IP-adresse, og user agent data,
                            herunder enhedstype, operativsystem, samt webbrowser, inkl. versions
                            numre.
                            <br />
                            <br />
                            <Typography id="time" component="span" fontWeight="bold">
                                Tidsstempler for datakommunikation
                            </Typography>
                            <br />
                            Tidsstempler registreres for den datakommunikation, som udveksles mellem
                            din enhed og ll33a.dk's databaseservere i en GeoSnapshot-session.
                            <br />
                            Tiden angives i relation til lokaltid i Danmark ved henholdsvis CET og
                            CEST (Central European (Summer) Time).
                            <br />
                            <br />
                            Du kan vælge at tilføje en selfie og/eller en SMS-verifikation.
                            <br />
                            <br />
                            <Typography id="selfie" component="span" fontWeight="bold">
                                Selfie (valgfri)
                            </Typography>
                            <br />
                            Ved at tilføje en selfie understøttes dokumentationen af din aktuelle
                            lokation med et selvportræt-foto.
                            <br />
                            Din selfie tages med kameraet på din enhed i samme session, som du har
                            anmodet om et GeoSnapshot. Herved optages og låses din selfie sammen med
                            din enheds lokationsdata.
                            <br />
                            <br />
                            <Typography id="smsveri" component="span" fontWeight="bold">
                                SMS-verifikation (valgfri)
                            </Typography>
                            <br />
                            Ved at sende en SMS, der indeholder en GeoSnapshot-genereret kode, fra
                            din mobiltelefon direkte til ll33a.dk's servere, knyttes dit
                            mobiltelefon-nummer sammen med dit unikke GeoSnapshot.
                            <br />
                            Med SMS-funktionen verificeres dit tilhørsforhold til den benyttede
                            mobiltelefon i samme session, hvori du har anmodet om et GeoSnapshot.
                            <br />
                            <br />
                            <Typography id="link" component="span" fontWeight="bold">
                                Verificeringslink
                            </Typography>
                            <br />
                            For at sikre og objektivt bekræfte integriteten af dokumentet,
                            indeholder GeoSnapshot dokumentationen et link til ll33a.dk's
                            checksum-verification service, der åbner mulighed for verificering vha.
                            SHA256-checksum metoden.
                            <br />
                            Dette er en sikker og anerkendt teknologi, som garanterer, at selv den
                            mindste ændring i dokumentet vil blive opdaget.
                            <br />
                            <br />
                            Alle detaljer fra sessionen præsenteres og arkiveres i et PDF-dokument,
                            som du nemt kan finde i din oversigt over dokumentation, på
                            kalendersiden under datoen for det pågældende GeoSnapshot. Hvis det er
                            markeret som "Included" (som er standardindstillingen), vil dokumentet
                            blive inkluderet i compliance-rapporten.
                            <br />
                            <br />
                            Dage, hvor der er anvendt GeoSnapshot, vil være markeret med orange på
                            kalendersiden.
                        </Typography>
                    </div>
                    <Divider />
                    <SignupBelt includeLandingRef />
                </div>
            </span>
        </Container>
    )
}

export const query = graphql`
    query {
        s1: file(relativePath: { eq: "geosnapshot_feature_illustration.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s2: file(relativePath: { eq: "snapshot-app-view.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s3: file(relativePath: { eq: "snapshot_pdf_view.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`
